<style scoped>
.table {
  font-size: 0.7em;
}
.font-smaller {
  font-size: 0.8em;
}
</style>

<template>
  <div class="mt-3">
    <h4>Advance Settings</h4>

    <b-form class="mt-5">
      <!-- Info from MyNetCalendarDB -->
      <div class="text-left mt-5">
        <h4
          class="text-left mb-4"
        >What activities do you want displayed in the Calendar and other views?</h4>

        <b-tabs pills content-class="mt-3 text-left">
          <b-tab title="To Residents" active>
            <b-form-group label>
              <b-form-checkbox-group
                style="flex-wrap: wrap;"
                v-model="settingsActRes"
                :options="settings.acts"
                name="buttons-1"
                size="sm"
                inline
              ></b-form-checkbox-group>
            </b-form-group>
          </b-tab>
          <b-tab title="To Admin">
            <b-form-group label>
              <b-form-checkbox-group
                style="flex-wrap: wrap;"
                v-model="settingsActAdmin"
                :options="settings.acts"
                name="buttons-1"
                size="sm"
                inline
              ></b-form-checkbox-group>
            </b-form-group>
          </b-tab>
          <b-tab title="In Day View">
            <b-form-group label>
              <b-form-checkbox-group
                style="flex-wrap: wrap;"
                v-model="settingsActDayView"
                :options="settings.acts"
                name="buttons-1"
                size="sm"
                inline
              ></b-form-checkbox-group>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </div>

      <b-button class="mt-5 mb-3" @click="onSave()" variant="primary">Save</b-button>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      settings: [],
      settingsActRes: [],
      settingsActAdmin: [],
      settingsActDayView: []
    };
  },
  computed: {},
  mounted() {
    this.getSettings();
  },
  components: {},
  methods: {
    getSettings() {
      var url = "getSettings.php";
      var data = {};
      this.$http.post(url, data, { emulateJSON: true }).then(res => {
        this.settings = res.body.meals;
        this.settings.acts = [...res.body.Act];
        this.settingsActRes = JSON.parse(
          ("[" + this.settings.db_actsCalResidents + "]").replace(/'/g, '"')
        );
        this.settingsActAdmin = JSON.parse(
          ("[" + this.settings.db_actsCalAdmin + "]").replace(/'/g, '"')
        );
        this.settingsActDayView = JSON.parse(
          ("[" + this.settings.db_actsDayIncluded + "]").replace(/'/g, '"')
        );
      });
    },
    onSave() {
      var newSettings = {};
      newSettings = {
        db_actsCalAdmin: JSON.stringify(this.settingsActAdmin)
          .replace("[", "")
          .replace("]", ""),
        db_actsCalResidents: JSON.stringify(this.settingsActRes)
          .replace("[", "")
          .replace("]", ""),
        db_actsDayIncluded: JSON.stringify(this.settingsActDayView)
          .replace("[", "")
          .replace("]", "")
      };

      var url = "saveSettings.php";
      var data = { settings: newSettings };
      this.$http.post(url, data, { emulateJSON: true }).then(res => {
        this.$store.commit("updateSettingsDB", res.body);
        this.$bvToast.toast(`Updated`, {
          title: "Settings",
          variant: "info",
          toaster: "b-toaster-top-center",
          autoHideDelay: 2000,
          appendToast: false
        });
      });
    }
  }
};
</script>
